@import '../../../base/assets/scss/_base/config';

.module-games-game-of-the-day {
  border: 1px solid $white;
  box-shadow: 0px 60px 60px -40px rgba(0, 71, 255, 0.3);
  border-radius: 24px;
  text-align: left;

  .img-wrap {
    height: 268px;
    overflow: hidden;
    border-radius: 24px 24px 0 0;
    position: relative;


    a {
      height: 100%;
      width: 100%;

      img {
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        object-fit: cover;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }
  .text-wrap {
    padding: 20px 20px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: $picasso;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--font-family-heading);
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 16px;
      letter-spacing: -0.2px;
      text-transform: uppercase;
      margin: 0 0 12px 0;
    }
    h3 {
      margin: 0;
      color: $gotham;
      font-family: var(--font-family-heading);
      font-size: 18px;
      text-align: left;
      font-style: normal;
      font-weight: 900;
      line-height: 20px;
      text-transform: uppercase;
      padding-right: 15px;
    }

    a {
      background: $brand-games;
      border-radius: 48px;
      color: $white;
      font-family: var(--font-family-heading);
      font-size: 13px;
      height: 44px;
      padding: 14px 24px;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 600;
      line-height: 16px;
    }
  }
}




@media screen and (min-width: map-get($breakpoints, xs)){
  .module-games-game-of-the-day {
    .text-wrap {
      padding: 22px 16px 36px;
      h2 {
        margin-bottom: 22px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)){
  .module-games-game-of-the-day {
    height: 380px;
    .text-wrap {
      h3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)){
  .module-games-game-of-the-day {
    .text-wrap {
      padding: 22px 20px 0 16px;
      h2 {
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)){
  .module-games-game-of-the-day {
    .text-wrap {
      padding: 28px 20px 0 16px;
    }
  }
}


@media screen and (min-width: map-get($breakpoints, xl)){
  .module-games-game-of-the-day {
    .text-wrap {
      padding: 18px 20px 0 16px;
      h2 {
        margin-bottom: 22px;
      }
    }
  }
}

